import * as React from 'react';
import { useState,useEffect } from 'react';
import {
  Box, Container, Typography, Button, Dialog, AppBar, Toolbar, IconButton, Slide, Grid
} from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CloseIcon from '@mui/icons-material/Close';
import JSZip from 'jszip';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QualtyResultView = () => {  
  const [assetPathData, setAssetPathData] = useState({});
  const [open, setOpen] = React.useState(false);


  // const randomStringFileName = () => {
  //   return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 3);
  // }

  const dowlaodReportAsset = async (assetPath) => {
    const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
    const fetchUrl = baseUrl + "QualityResultAssetContentFetchService";
    const response = await fetch(fetchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/zip',
      },
      body: JSON.stringify({ blobPath: assetPath }),
    });
    if (response && response.status === 200) {
      // const blob = await response.blob();
      // const blobURL = URL.createObjectURL(blob);
      // return "Download Success";
      return Promise.resolve(response.blob());
    } else {
      return "Download Failed";
    }
  };

  // const deleteReportAsset = async (assetPath) => {    
  // }

  const handleReportViewerModalOpen = (paramFolderPath) => {
    // console.log(paramFolderPath);
    // var folderPath = "Testresults/BCA/BCA-TestAutomation-UI-Test/Reports/10302024143338";
    // var fileName = "10302024143338"; //+ "_" + randomStringFileName() + ".zip";
    dowlaodReportAsset(paramFolderPath).then((result) =>
      {
        JSZip.loadAsync(result).then((zip) => {
          zip.forEach((relativePath, zipEntry) => {
            console.log(relativePath);
          });
          return zip.file("index.html").async("string");
        }).then(function success(text) {          
          // document.getElementById("para").innerHTML = text;
          var externalWindowPopup = window.open("about:blank", "", "_blank");
          externalWindowPopup.document.write(text);
        }, function error(e) {
          console.log(e);
        });
      }
    )
    setOpen(false);
  };

  const handleReportViewerModalClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    async function fetchData() {
      const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
      const fetchUrl = baseUrl + "QualityResultAssetPathsFetchService";
      const response = await fetch(fetchUrl, {
        // headers: {  
        //     'Authorization': `Bearer ${accessToken}`, 
        // }   
      });
      if (response && response.status === 200) {
        const responseData = await response.json();
        setAssetPathData(responseData)
      }
    }
    fetchData();
  }, []);
  
  return (    
    <>    
      <Box display="flex" flexDirection="column" minHeight="100vh" >    
        <Box bgcolor="#EEF4FF" pt={6} pb={3} px={10} sx={{margin:0}}>  
          <Container maxWidth={false}>            
            <Typography fontWeight={700} fontSize="20px" gutterBottom sx={{color:'#00184D'}} > 
              Quality Test Results & Reports
            </Typography>            
          </Container>  
        </Box>
        <Box px={10} pb={10} sx={{margin:0}}>
          <Container maxWidth={false}>  
            {Object.keys(assetPathData).map((item, index) => (  
              <Box p={2} key={index}>  
                {/* <Typography mt="32px" mb="0px" fontWeight={700} fontSize="20px" gutterBottom sx={{ color: '#00184D' }}>  
                  Container : {assetPathData[item].StorageContainerName}
                </Typography>
                <Typography mt="32px" mb="0px" fontWeight={700} fontSize="20px" gutterBottom sx={{ color: '#00184D' }}>  
                  Folder : {assetPathData[item].StorageFolderName}
                </Typography>  
                Reports */}
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {/* {Array.from(Array(6)).map((_, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <Item>xs=2</Item>
                    </Grid>
                  ))} */}
                   {assetPathData[item].StorageFilePaths.map((item, idx) => (                    
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <Button variant="outlined" onClick={() => handleReportViewerModalOpen(item.AssetBlobDirectoryPath)} startIcon={<SummarizeIcon />}>
                        {item.AssetDisplayName.split('|')[0]} <br /> {item.AssetDisplayName.split('|')[1]}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
                {/* <ul>  
                  {assetPathData[item].StorageFilePaths.map((item, idx) => (
                    // <li key={idx}><a href={item.AssetIndexPath} rel='noreferrer' target='_blank'>{item.AssetDisplayName}</a></li>
                    // AssetBlobDirectoryPath
                    <li>
                      <Button variant="outlined" onClick={() => handleReportViewerModalOpen(item.AssetBlobDirectoryPath)} startIcon={<SummarizeIcon />}>
                        {item.AssetDisplayName}
                      </Button>
                    </li>
                  ))}  
                </ul>   */}
              </Box>  
            ))}  
          </Container>  
        </Box>
      </Box>

      {/* Quality Result Viewer Modal Window */}
      <Dialog fullScreen open={open} onClose={handleReportViewerModalClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleReportViewerModalClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div"></Typography>
            <Button autoFocus color="inherit" onClick={handleReportViewerModalClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        {/* <List>
          <ListItemButton>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItemButton>
          <Divider />
          <ListItemButton>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItemButton>
        </List> */}
        <div id="para">

        </div>
      </Dialog>
    </>  
  );  
}; 

export default QualtyResultView;  